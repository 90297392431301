import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Notifications from "../helpers/toast-notifications";
import i18n from "../i18n";
import tablesStore from "./tablesStore";
import mainViewsStore from "./modules/mainViews/mainViewsStore";
import buffetMainViewStore from "./modules/buffet/buffetMainViewStore";
import gradeEduclassShared from "./modules/mainViews/grade-educationClass-shared/store";
import studentStore from "./modules/students/studentStore";
import parents from "./modules/users/parents";
import admins from "./modules/users/admins";
import teachersStore from "./modules/teachers/teachersStore";
import cashierFinancialOrder from "./modules/financial-order/cashierFinancialOrder";
import accountantFinancialOrder from "./modules/financial-order/accountantFinancialOrder";
import costCenter from "./modules/costCenter";
import cashierBox from "./modules/cashierBox";
import router from "../router";
import timeAttendance from "@/store/modules/HR/time_attendence";
import employees from "@/store/modules/HR/employees";
import calculations from "@/store/modules/HR/calculations";
import loans from "@/store/modules/HR/loans";
import payments from "@/store/modules/HR/payments";
import vacations from "@/store/modules/HR/vacations";
import variations from "@/store/modules/HR/variations";
import payinstallment from "@/store/modules/pay-installments/payinstallment";
import restrictions from "@/store/modules/restrictions";

Vue.use(Vuex);
// online serveer domain
// https://bahlol-back.incentive-dev.com
// https://incentive-dev.com
// http://192.168.100.2:8080
// https://back-demo.incentive-dev.com
// http://127.0.0.1:8080
// https://robogeex-back.incentive-dev.com
// https://lbbis-back.incentive-dev.com
// https://ajyal-back.incentive-dev.com
// https://friendship-back.incentive-dev.com
// https://om-aldardaa-back.incentive-dev.com

export default new Vuex.Store({
  state: {
    serverDomain: "https://ajyal-back.incentive-dev.com/api",
    barImage: "",
    drawer: null,
    userData: {
      username: "",
      abilities: [],
    },
    termModel: [],
    drawerList: [],
    schSetting: {
      SCHOOL_NAME: 'Incentive School',
      COIN_CODE: '€',
      COIN_NAME: 'Euro',
      SCHOOL_IMAGE: require("@/assets/Logo 2.png"),
    }, 
    activeDash: true,
    routeNameAddition: "",
    isDarkMode: false,
    acTapHrIn: 0,
    tabRoute: 0,
    tabTrip: 0,
    tabAi: 0,
    tabWorkFlow: 0,
    tabTeachers: 0,
    studentTap: 0,
  },
  mutations: {
    SET_DRAWER_LIST(state, payload) {
      state.drawerList = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_ACTIVE_DASH(state, payload) {
      state.activeDash = payload;
    },
    setUserData(state, userInfo) {
      state.userData.username = userInfo.user_name;
      state.userData.abilities = userInfo.ability;
    },
    setTermModel(state, payload) {
      state.termModel = payload;
    },
    setRouteNameAddition(state, addition) {
      state.routeNameAddition = addition;
    },
    SET_DARK_MODE(state, payload) {
      state.isDarkMode = payload;
    },
    SET_ACTABHRIN(state, payload){
      state.acTapHrIn = payload;
    },
    SET_TABROUTE(state, payload){
      state.tabRoute = payload;
    },
    SET_TABTRIP(state, payload){
      state.tabTrip = payload;
    },
    SET_TABAI(state, payload){
      state.tabAi = payload;
    },
    SET_TABWORKFLOW(state, payload){
      state.tabWorkFlow = payload;
    },
    SET_TABTEACHERS(state, payload){
      state.tabTeachers = payload;
    },
    SET_studentTab(state, payload){
      state.studentTap = payload;
    },
  },
  actions: {
    async userLogin({ commit }, userData) {
      try {
        axios.defaults.headers.common["term"] = userData.termId;
        const logInResponse = await axios.post("/auth/login", {
          username: userData.username,
          password: userData.password,
        });
        localStorage.setItem("term", logInResponse.data.data.term.id);
        localStorage.setItem("termModel", JSON.stringify(logInResponse.data.data.term));
        console.log("login response", logInResponse);
        commit("setUserData", logInResponse.data.data);
        commit("setTermModel", logInResponse.data.data.term);
        localStorage.setItem(
          "school_user_data",
          JSON.stringify(logInResponse.data.data)
        );
        localStorage.setItem(
          "access_token",
          logInResponse.data.data.access_token
        );
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + logInResponse.data.data.access_token;
        Notifications(
          i18n.t("login success"),
          { timeout: 1000, rtl: true },
          "success"
        );
        router.replace("/");
      } catch (err) {
      } finally {
      }
    },
    autoLogIn({ commit }) {
      commit( "setUserData", JSON.parse( localStorage.getItem("school_user_data") ) );
      commit( "setTermModel", JSON.parse( localStorage.getItem("termModel") ) );
      axios.defaults.headers.common["term"] = localStorage.getItem("term");
      axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("access_token");
    },
  },
  getters: {
    getUserName(state) {
      return state.userData.username;
    },
    getUserAbilities(state) {
      return state.userData.abilities;
    },
    getRouteNameAddition(state) {
      return state.routeNameAddition;
    },
  },
  modules: {
    tablesStore,
    ...mainViewsStore,
    ...buffetMainViewStore,
    ...studentStore,
    ...teachersStore,
    ...gradeEduclassShared,
    costCenter,
    cashierBox,
    parents,
    admins,
    cashierFinancialOrder,
    accountantFinancialOrder,
    timeAttendance,
    employees,
    calculations,
    loans,
    payments,
    vacations,
    variations,
    payinstallment,
    restrictions,
  },
});
